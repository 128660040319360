<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Delivery Orders')}}</h2>
      </div>
      <div class="vx-col w-full lg:w-1/2 items-end">
        <shipment :user="merchant" :type="type" :shipmentModal="shipmentModal" @shipmentModal="shipmentModal = $event" @loadShipments="loadShipments"></shipment>
      </div>
    </div>
    <div class="flex flex-wrap items-center gap-x-4">
      <table-filter :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
      <export-button class="m-0 whitespace-nowrap"></export-button>
    </div>

    <div class="xl:float-left clearfix xl:w-3/5 mt-4 w-full">
      <print-label-button class="mr-4 float-left whitespace-nowrap step-3" :selected="selected"></print-label-button>
      <request-button v-if="merchantStatus == 'active'" class="mr-4 float-left whitespace-nowrap step-4" :selected="selected" @selected="selected = $event" @loadShipments="loadShipments"></request-button>
      <cancel-shipments-button v-if="merchantStatus == 'active'" class="mr-4 float-left whitespace-nowrap" :selected="selected" @selected="selected = $event" @loadShipments="loadShipments"></cancel-shipments-button>
      <restart-orders v-if="merchantStatus == 'active'" class="mr-4 float-left whitespace-nowrap" :selected="selected" @selected="selected = $event" @loadShipments="loadShipments"></restart-orders>
      <exchange-order v-if="merchantStatus == 'active'" class="mr-4 float-left whitespace-nowrap" :selected="selected" @returnPackages="returnPackages('Exchange Packages', selected[0])"></exchange-order>
      <div class="float-left w-full py-2 text-sm" v-if="checkAllInPage && printableStatuses.includes(filterValue) && filters.length == 1">
        <span>{{(checkAll ? totalRows : selected.length) + ' ' + $t('orders are selected') + ' '}}</span>
        <span class="text-primary cursor-pointer underline" @click="checkAll = true">{{$t('Select all') + ' ' + totalRows + ' ' + $t('Orders')}}</span>
      </div>
    </div>
    <shipblu-table
      :sst="true"
      class="step-1"
      orders
      multiple
      :checkAll="checkAll"
      :checkAllInPage="checkAllInPage"
      v-model="selected"
      :max-items="maximumItems"
      @checkAll="checkAll = $event"
      @checkAllInPage="checkAllInPage = $event"
      @searchDate="handleSearchDate"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      searchDate
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Info')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Phone')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('CoD')}}</shipblu-th>
        <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
        <shipblu-th>{{$t('On Hold')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Delivered On')}}</shipblu-th>
        <shipblu-th 
          class="step-12"
          v-if="(!$route.params.merchantID && !$route.params.warehouseID) || $store.state.AppActiveUser.userRole === 'customer-support'">
          {{$t('Actions')}}
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-delivery-orders`, params: { type: 'delivery-orders', orderID: data[indextr].id }}">
          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
            <div v-if="data[indextr].fulfillment_provider_metadata" class="inline-flex items-center">
              <p class="text-warning"><feather-icon icon="PackageIcon" class="w-4"/></p>
              <p class="text-xs text-warning font-medium px-1">{{$t('FulFillment')}}</p>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].createdOn">
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].phone">
            {{ data[indextr].customer.phone }}
          </shipblu-td>
          <shipblu-td :data="data[indextr].packages">
            {{ data[indextr].packages.length }}
          </shipblu-td>
          <shipblu-td :data="data[indextr].cashOnDelivery">
            {{ data[indextr].cash_amount && parseInt(data[indextr].cash_amount) > 0 ? ((Number(data[indextr].cash_amount)).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'N/A' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].pickupDate">
            {{ data[indextr].pickup_date?  new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].on_hold">
            <template v-if="data[indextr].on_hold !== null">
              <div class="bg-warning h-3 w-3 inline-block rounded-full mr-2"></div>
              <span>On Hold</span>
            </template>
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].deliveredOn">
            {{ data[indextr].delivered_date && data[indextr].status === 'delivered' ? new Date(data[indextr].delivered_date).toLocaleDateString('fr-CA') : '' }}
          </shipblu-td>

          <shipblu-td v-if="(!$route.params.merchantID && !$route.params.warehouseID) || $store.state.AppActiveUser.userRole === 'customer-support'">
            <div @click="removeAttribute($event)">
              <vs-dropdown
                :disabled="merchantStatus == 'on_hold'"
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                  class="mr-2"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="viewShipment(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('View')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <div v-if="$store.state.AppActiveUser.userRole !== 'customer-support'">
                    <!-- ALLOW CANCEL IF NOT DELIVERED OR RETURNED -->
                    <vs-dropdown-item v-if="(data[indextr].status === 'created' || data[indextr].status === 'pickup_requested') && !data[indextr].fulfillment_provider_metadata && merchantStatus == 'active'"
                      @click="updateShipmentStatus(data[indextr],'cancel this order?', 'cancelled')">
                      <span class="flex items-center">
                        <feather-icon
                          icon="XCircleIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Cancel Shipment')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item v-if="(data[indextr].status === 'picked_up' || data[indextr].status === 'in_transit' || data[indextr].status === 'out_for_delivery' || data[indextr].status === 'delivery_attempted') && merchantStatus == 'active'"
                      @click="updateShipmentStatus(data[indextr], 'return this order to origin?', 'return_to_origin')"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="XCircleIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Return to Origin')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <!-- ACTIONS FOR DELIVERED -->
                    <vs-dropdown-item v-if="data[indextr].status === 'delivered' && merchantStatus == 'active'"
                      @click="returnPackages('Return Packages',data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="DeleteIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Pickup to Return')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item  v-if="data[indextr].status === 'delivered' && merchantStatus == 'active'"
                      @click="returnPackages('Exchange Packages', data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="Minimize2Icon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Pickup to Exchange')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item v-if="data[indextr].status === 'delivered'" @click="getInvoice(data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="DownloadIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Download Invoice')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <!-- ACTIONS FOR CANCELLED -->
                    <vs-dropdown-item v-if="(data[indextr].status === 'cancelled') && merchantStatus == 'active'" 
                    @click="restartOrders(data[indextr])" >
                      <span class="flex items-center">
                        <feather-icon
                          icon="RotateCcwIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Restart Shipment')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </div>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <div :class="$route.query.from ? 'block' : 'hidden'">
      <div :class="$route.query.from ? 'exchange-modal rounded-md' : ''">
        <div class="title py-4 flex">
          <img class="ml-6 mr-2" src="../../assets/images/pages/Group.svg"/>
          <p class="text-primary text-base font-medium">{{ $t('Exchange Request') }}</p>
        </div>
        <p class="py-4 px-6 text-base font-medium">{{ $t('Select the delivered order you want, and pickup to exchange!') }}</p>
      </div>
      <div :class="$route.query.from ? 'show-exchange-modal' : ''"></div>
    </div>
    <return-and-exchange-package :userMerchant="userMerchant" :title="title" :governorateId="governorateId" :merchant="merchant" :returnPackagesFlag="returnPackagesFlag" :returnPackagesData="returnPackagesData"
      @returnPackagesFlag="returnPackagesFlag = $event" @selected="selected = $event" @loadShipments="loadShipments"></return-and-exchange-package>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import Shipment from './components/Shipment.vue'
import i18nData from '../../i18n/i18nData.js'
import vSelect from 'vue-select'
import common  from '@/assets/utils/common'
import ReturnAndExchangePackage from './components/ReturnAndExchangePackage.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportButton from '../headOfFleet/components/ExportButton.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import CancelShipmentsButton from '../admin/components/CancelShipmentsButton.vue'
import RequestButton from './components/RequestButton.vue'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import RestartOrders from './components/RestartButton.vue'
import ExchangeOrder from './components/ExchangeOrder.vue'
import Shepherd from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd.css'
import TableFilter from './components/TableFilter.vue'

export default {
  data () {
    return {
      common,
      merchantUID: this.$store.state.AppActiveUser.uid,
      type: 'Add',
      shipmentModal: false,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      shipmentURL: 'api/v1/delivery-orders/',
      selected: [],
      tableList: [
        'shipblu-th: Component',
        'shipblu-tr: Component',
        'shipblu-td: Component',
        'thread: Slot',
        'tbody: Slot',
        'header: Slot'
      ],
      shipments: [],
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'pickup requested',
          value: 'pickup_requested'
        },
        {
          name: 'out for pickup',
          value: 'out_for_pickup'
        },
        {
          name: 'pickup rescheduled',
          value: 'pickup_rescheduled'
        },
        {
          name: 'picked up',
          value: 'picked_up'
        },
        {
          name: 'in transit & en route',
          value: 'in_transit, en_route'
        },
        {
          name: 'out for delivery',
          value: 'out_for_delivery'
        },
        {
          name: 'delivery attempted',
          value: 'delivery_attempted'
        },
        {
          name: 'delivered',
          value: 'delivered'
        },
        {
          name: 'RTO Requested',
          value: 'rto_requested'
        },
        {
          name: 'On Hold',
          value: 'on_hold'
        },
        {
          name: 'out for return',
          value: 'out_for_return'
        },
        {
          name: 'Returned',
          value: 'returned'
        },
        {
          name: 'cancelled',
          value: 'cancelled'
        }
      ],
      filters: [],
      merchant: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      searchVal: this.$route.query.search ? this.$route.query.search : '',
      offset: 0,
      shipment: {},
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      merchantID: '',
      merchantStatus: '',
      title: '',
      returnPackagesFlag: false,
      returnPackagesData: {},
      file_URl: '',
      trackingData: {},
      trackingStatus: '',
      tourMerchantOrders: '',
      governorateId: '',
      searchInProgress: false,
      searchedValue: ' ',
      fromDate: '',
      toDate: '',
      criteria: '',
      userMerchant: {},
      tableLoader: false,
      tourForDelevryOrders:'',
      checkAll: false,
      printableStatuses: ['pickup_rescheduled', 'pickup_requested', 'created', 'out_for_pickup'],
      checkAllInPage: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      if (this.$route.params.warehouseID) {
        this.loadWarehouseShipments()
      } else {
        this.$router.push({
          name: 'merchant-shipments',
          query: {
            filter: this.filters,
            page: this.currentPage
          }
        }).catch(() => {})
        this.loadShipments()
      }
    },
    '$route.query.filter' () {
      if (this.$route.params.warehouseID && !this.$route.params.merchantID && !this.$route.query.filter) {
        this.filters = []
        this.loadWarehouseShipments()
      } else if (!this.$route.query.filter) {
        this.filters = []
        this.loadShipments()
      } else if (this.$route.query.from) {
        this.filters = [this.$route.query.filter]
        this.loadShipments()
      }
    },
    'searchVal' () {
      this.$router.push({
        query: {
          search: this.searchVal,
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      if (this.$route.params.warehouseID) {
        this.loadWarehouseShipments()
      } else {
        this.loadShipments()
      }
    },
    restartOrders (data) {
      const orders = []
      orders.push(data.id)
      sendRequest(false, false, this, 'api/v1/orders/restart-cancelled/', 'post', {orders_id: orders}, true,
        () => {
          this.loadShipments()
        }
      )
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    onTourComplete () {
      this.$cookies.set('tourMerchantOrders', true, '100y')
      this.$cookies.set('reloaded', true, '100y')
    },
    loadWarehouseShipments () {
      this.searchInProgress = true
      const query = `?current_warehouse=${this.$route.params.warehouseID}&offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&${this.filters.includes('rto_requested') ? 'rto_requested=true' : `status=${this.filters}`}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_before=${this.toDate ? this.toDate : ''}`
      sendRequest(false, false, this, `${this.shipmentURL}${query}`, 'get', null, true,
        (response) => {
          this.shipments = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    handleSearchDate (start, end, criteria) {
      this.fromDate = start ? new Date(start).toISOString().split('T')[0] : ''
      this.toDate = end ? new Date(end).toISOString().split('T')[0] : ''
      this.criteria = criteria
      this.$route.params.warehouseID ? this.loadWarehouseShipments() : this.loadShipments()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.$route.params.warehouseID ? this.loadWarehouseShipments() : this.loadShipments()
    },
    loadShipments () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&${Array.isArray(this.filters) && this.filters.includes('on_hold') ? 'on_hold=true' : this.filters.includes('rto_requested') ? 'rto_requested=true' : `status=${Array.isArray(this.filters) ? this.filters.filter(item => item !== 'fulfillment') : ''}`}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_before=${this.toDate ? this.toDate : ''}`
      sendRequest(true, false, this, `api/v1/delivery-orders/${query}`, 'get', null, true,
        (response) => {
          this.shipments = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false 
    },
    returnPackages (title, data) {
      this.governorateId = data.customer.address.zone.city.governorate.id
      this.title = title
      this.returnPackagesFlag = true
      this.returnPackagesData = {
        ...data,
        declared_value: 0
      }
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all' || (this.filterValue === 'rto_requested' && this.filters.includes('rto_requested')) || (this.filterValue === 'on_hold' && this.filters.includes('on_hold'))) {
        this.filters = []
        if (this.filterValue === 'rto_requested' && !this.filters.includes('rto_requested')) {
          this.filters.push('rto_requested')
        } else if (this.filterValue === 'on_hold' && !this.filters.includes('on_hold')) {
          this.filters.push('on_hold')
        }
      } else if (this.filters.includes('rto_requested')) {
        this.filters.splice(this.filters.indexOf('rto_requested'), 1)
      } else if (this.filters.includes('on_hold')) {
        this.filters.splice(this.filters.indexOf('on_hold'), 1)
      }
      this.selected = []
      this.currentPage = 1
      if (!this.$route.params.merchantID && !this.$route.params.warehouseID) {
        this.$router.push({
          name: 'merchant-shipments',
          query: {
            page: this.currentPage,
            filter: this.filters
          }
        }).catch(() => {})
        this.loadShipments()
      }
    },
    viewShipment (data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-delivery-orders`,
        params: {
          type: 'delivery-orders',
          orderID: data.id
        }
      }).catch(() => {})
    },
    updateShipmentStatus (data, sentence, status) {
      this.trackingData = data
      this.trackingStatus = status
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: i18nData[this.$i18n.locale]['Are you sure you want to '] + i18nData[this.$i18n.locale][sentence],
        acceptText: i18nData[this.$i18n.locale]['Yes'],
        accept: this.confirmUpdateShipmentStatus
      })
    },
    confirmUpdateShipmentStatus (data, status) {
      let trackings = []
      if (data) {
        trackings = [
          {
            order: data.id,
            packages: data.packages.map(item => item.package.id),
            status
          }
        ]
      } else {
        trackings = [
          {
            order: this.trackingData.id,
            packages: this.trackingData.packages.map(item => item.package.id),
            status: this.trackingStatus
          }
        ]
      }
      sendRequest(false, false, this, 'api/v1/tracking/', 'post', trackings, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadShipments()
        }
      )
    },
    getInvoice (data) {
      sendRequest(false, false, this, `api/v1/billing/merchants/${this.merchantID}/invoices/?invoiceorder__order=${data.id}`, 'get', null, true,
        (response) => {
          if (response.data.results.length > 0) {
            this.downloadInvoice(response.data.results[0].id)
          } else {
            this.$vs.notify({
              color:'warning',
              title:i18nData[this.$i18n.locale]['Warning'],
              text:i18nData[this.$i18n.locale]['Invoice isn\'t ready'],
              position: 'top-center'
            })
          }
        }
      )
    },
    downloadInvoice (invoiceId) {
      sendRequest(false, false, this, `api/v1/billing/invoices/${invoiceId}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    closeModal () {
      this.returnPackagesFlag = false
      this.loadShipments()
    }
  },
  components: {
    Shipment,
    vSelect,
    ReturnAndExchangePackage,
    ShipbluTable,
    ShipbluPagination,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    PrintLabelButton,
    CancelShipmentsButton,
    RequestButton,
    ShipbluPrompt,
    ExchangeOrder,
    RestartOrders,
    Shepherd,
    TableFilter
  },
  mounted () {
    if (this.$cookies.get('tourMerchantOrders') === 'false') {
      this.$cookies.set('tourMerchantOrders', true, '100y')
      this.tourForDelevryOrders.start()
    }
    this.$emit('setAppClasses', 'shipment-page')
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      this.tourMerchantOrders = (this.$cookies.get('tourMerchantOrders') === 'true')
    }
  },
  created () {
    const vueJs = this
    if (this.$cookies.get('tourMerchantOrders') === 'false') {
      this.tourForDelevryOrders = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          cancelIcon: {
            enabled: true
          },
          classes: 'shadow-md bg-purple-dark',
          scrollTo: {behavior: 'smooth', block: 'center'}
        }
      })
      const setpsArray = [
        {
          text: this.$t('Here you can view all your delivery orders.'),
          element:'.step-1',
          button1: this.$t('Cancel'),
          button2: this.$t('Next'),
          next: false
        },
        {
          text: this.$t('You can also filter by their status'),
          element: '.step-2',
          button1: this.$t('Back'),
          button2: this.$t('Next'),
          next: true
        },
        {
          text: this.$t('Now, print your shipping label'),
          element: '.step-3',
          button1: this.$t('Back'),
          button2: this.$t('Next'),
          next: true
        },
        {
          text: this.$t('If your profile is reviewed and verified, you can request pickup here.'),
          element: '.step-4',
          button1: this.$t('Back'),
          button2: this.$t('Next'),
          next: true,
          router: 'merchant-supplies-shop'
        },
        {
          text: this.$t('You may request flyers or buy shipping boxes and supplies from here.'),
          element: '.Supplies',
          button1: this.$t('Back'),
          button2: this.$t('Next'),
          next: true,
          router: 'merchant-account-financials'

        },
        {
          text: this.$t('Click here to add a payout method, know when you will get your COD and explore your pricing plan.'),
          element: '.Financials',
          button1: this.$t('Back'),
          button2: this.$t('Next'),
          next: true
        },
        {
          text: this.$t('If you have larger monthly volume of shipping, contact our sales team to receive discounts. Thank you for choosing ShipBlu. We look forward to serve you!'),
          element: '.step-7',
          button1: this.$t('Back'),
          button2: this.$t('Done!'),
          next: true
        }
      ]
      setpsArray.forEach((element) => { 
        this.tourForDelevryOrders.addStep({
          text: element.text,
          attachTo: {
            element: element.element,
            on: 'bottom'
          },
          buttons: [
            {
              action () {
                return element.next ? this.back() : this.cancel()
              },
              classes: 'shepherd-button-secondary',
              text: element.button1
            },
            {
              action () {
                if (element.router) {
                  vueJs.$router.push({
                    name: element.router
                  }).catch(() => {})
                }
                this.next()
              },
              text: element.button2
            }
          ]
        })
      })
      const test = this.tourForDelevryOrders
      document.addEventListener('keydown', function (event) {
        if (event.key === 'Enter') {
          test.next()
        }
      })
    }
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantID = user.id
      this.merchantStatus = user.status
      this.merchant = user
    })
    this.$router.push({
      query: {...this.$route.query}
    }).catch(() => {})
    this.offset = (this.currentPage - 1) * this.maximumItems
    if (this.$cookies.get('reloaded') === 'false') {
      this.$cookies.set('reloaded', true)
      if (this.tourMerchantOrders === false) {
        this.$intro().start()
      }
    }
    this.tourMerchantOrders = (this.$cookies.get('tourMerchantOrders') === 'true')
    if (this.$route.params.warehouseID) {
      this.loadWarehouseShipments()
    } else if (this.$route.query.filter) {
      this.filters = typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter
      this.loadShipments()
    } else {
      this.filters = []
      this.loadShipments()
    }
  }
}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
.bg-orange{
  background: #F58231 !important;
}

</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
.exchange-modal {
  width: 500px;
  position: fixed;
  top: calc(50% - 58.605px);
  right: calc(50% - 250px);
  z-index: 999999;
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.38);
  transition: all 1s ease-in-out;
  animation: hideAnimation 0s ease-in-out 4s;
  animation-fill-mode: forwards;
  p {
    color: #6C84A3;
  }
}
.show-exchange-modal {
  visibility: visible;
  opacity: 1 !important;
  position: fixed;
  inset: 0;
  z-index: 99999;
  background: rgba(10, 50, 102, 0.24);
  backdrop-filter: blur(2px);
  opacity: 0;
  animation: hideAnimation 0s ease-in-out 4s;
  animation-fill-mode: forwards;
}
@keyframes hideAnimation {
  to {
    visibility: hidden;
    width: 0;
    height: 0;
    transition: all 1s ease-in-out;
  }
}
@media (max-width: 767px) {
  .exchange-modal {
    width: unset;
    max-width: 300px;
    right: calc(50% - 150px);
  }
}
.title {
  background: rgba(220, 238, 255, 0.5);
  border-radius: 6px 0px 0px 6px;
  color: '#1C5BFE'
}
.shepherd-enabled.shepherd-element {
    z-index: 51900;
}
.shepherd-modal-is-visible{
    z-index: 51900;
}
.shepherd-text {
    padding: 0 2em;
}
.shepherd-header {
    padding: 0 0.75rem;
    height: 35px;
}
.shepherd-footer{
  margin-top: 16px;
}
.shepherd-button {
  background: #1C5BFE;
}
.filter-z-index {
  z-index: 9999;
  user-select: none;
}
</style>
